import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import {TinyButton as ScrollUpButton} from 'react-scroll-up-button' 
import Burger from '../components/BurgerMenu';

const PageTemplate = css`
  .site-main {
    padding-bottom: 4vw;
  }
  .text-center {
    text-align: center;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Burger/>
    <ScrollUpButton style={{
        fill: 'var(--textLink)',
        background: 'none',
        left: '20px'
    }} />
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <SiteNav />
      </header>
      <main className="site-main" css={[SiteMain, outer]} style={{
        backgroundColor: 'var(--postBg)',
        color: 'var(--textNormal)',
        transition: 'background-color 0.3s ease',
      }}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content" style={{
            backgroundColor: 'var(--postBg)',
            color: 'var(--textNormal)',
            transition: 'background-color 0.3s ease',
          }}>
            <div className="post-content">
              <img src="https://cdn.shopify.com/s/files/1/1694/1769/files/irvine.jpg?48261" />
              <h2>Who are we?</h2>
              <p>
                <em>Trusted Tech Team Blog</em> provides insights and news for the enterprise technology industry. As a value-added reseller (VAR), Trusted Tech Team understands the complexities of software licensing, so we simplify the process for you.
              </p>
              <p>
                As a Microsoft Gold Partner, we provide competitive software pricing, friendly customer service, and reliable technical support with an authentically human touch. Our in-house Support Specialists and Licensing Engineers answer your questions in-depth while resolving your most pressing technical issues.
              </p>
              <p>
               If you need software ASAP, our Dedicated Account Reps quickly fulfill your orders with unmatched efficiency. Most digital products are typically delivered within 30 minutes during normal business hours, and within 4 hours on weekends.
              </p>
              <p>
                Trusted Tech Team is built on trust, so we go above and beyond the average VAR. As enthusiastic IT specialists, we diligently satisfy your business needs and give you peace of mind.
              </p>
              <hr></hr>
              <div className="text-center">
                <h2>COME SAY HELLO</h2>
                <h2><a target="_blank" href="https://www.trustedtechteam.com/">WWW.TRUSTEDTECHTEAM.COM</a></h2>
              </div>
              <hr></hr>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
